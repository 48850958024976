import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";


const initialState = {
    rules:[]
  };


const setComplianceRules = (state, action) => {
    return updateObject(state, {
      rules: action.table_data,
      loadingtable: false
    });
  };
  
  const getComplianceRules = (state, action) => {
    return updateObject(state, {
      loadingtable: true
    });
  };
  
  const getComplianceRules_fail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loadingtable: false
    });
  };


  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FETCH_COMPLIANCE_RULES_START:
        return getComplianceRules(state, action);
      case actionTypes.FETCH_COMPLIANCE_RULES_SUCCESS:
        return setComplianceRules(state, action);
      case actionTypes.FETCH_COMPLIANCE_RULES_FAIL:
        return getComplianceRules_fail(state, action);

      default:
        return state;
    }
  };
  
  export default reducer;