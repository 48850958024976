import * as config from "../shared/config/config";

const Reducer = (state, action) => {
  const {
    ACTIONS: {
      SET_APM_PRODUCTS,
      SET_SELECTED_APM_PRODUCT_NAME,
      SET_SHARED_PRODUCTS,
      SET_APM_PRODUCT_OVERVIEW,
      SET_APM_PRODUCT_PHASES,
      SET_APM_PRODUCT_GATES,
      SET_SELECTED_GATE_INDEX_FROM_OVERVIEW,
      SET_QUICK_FIND_DROPDOWN_OPTIONS,
      SET_SELECTED_QUICK_FIND_DROPDOWN_OPTION,
      SET_APM_PRODUCT_FLOWS,
      SET_SELECTED_SWITCH_FILTER,
      SET_NOTIFICATION,
      SET_USER,
      SET_PROCESSES,
      SET_TABLE
    }
  } = config;
  const { type, payload } = action;

  switch (type) {
    case SET_APM_PRODUCTS:
      return {
        ...state,
        apmProducts: payload,
      };
      case SET_TABLE:
        return{
          ...state,
          table_data:payload
        }

    case SET_SELECTED_APM_PRODUCT_NAME:
      return {
        ...state,
        selectedApmProductName: payload
      }

    case SET_SHARED_PRODUCTS:
      return {
        ...state,
        sharedProducts: payload,
      };

    case SET_APM_PRODUCT_OVERVIEW:
      return {
        ...state,
        apmProductOverview: payload
      }

    case SET_APM_PRODUCT_PHASES:
      return {
        ...state,
        apmProductPhases: payload
      }

    case SET_APM_PRODUCT_GATES:
      return {
        ...state,
        apmProductGates: payload
      }

    case SET_SELECTED_GATE_INDEX_FROM_OVERVIEW:
      return {
        ...state,
        selectedGateIndexFromOverview: payload
      };

    case SET_QUICK_FIND_DROPDOWN_OPTIONS:
      return {
        ...state,
        quickFindDropdownOptions: payload
      }

    case SET_SELECTED_QUICK_FIND_DROPDOWN_OPTION:
      return {
        ...state,
        selectedQuickFindDropdownOption: payload
      };

    case SET_APM_PRODUCT_FLOWS:
      return {
        ...state,
        apmProductFlows: payload
      }

    case SET_SELECTED_SWITCH_FILTER:
      return {
        ...state,
        selectedSwitchFilter: payload
      }

    case SET_NOTIFICATION:
      return {
        ...state,
        message: payload,
      };
    case SET_USER:
      return {
        ...state,
        user: payload,
      };
    
    case SET_PROCESSES:
      return {
        ...state,
        process: payload,
      };
  
    default:
      return state;
  }
};

export default Reducer;
