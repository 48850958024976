import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    error: null
};

const auditSuccess = ( state, action ) => {
    return updateObject( state, { 
        error: null
    });
};

const auditFail = ( state, action ) => {
    return updateObject( state, { 
        error: action.error
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SUBMIT_AUDIT_FAIL: return auditFail(state, action);
        case actionTypes.SUBMIT_AUDIT_SUCCESS: return auditSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;