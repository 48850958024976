import React from "react";
import { Link } from "react-router-dom";
import "./complianceStatus.scss";

const ComplianceStatus = ({setDisplayLegend}) => (
    <div className="complianceStatus" data-testid="complianceStatus">
        <p className="complianceStatus-title">
            Compliance Status
            <Link to="/v2/applications/apm/compliancerules" onClick={() => {
                if(typeof setDisplayLegend === "function") {
                    setDisplayLegend(false)
                }
            }}>Rules</Link>
        </p>
        <div className="complianceStatus-items">
            <div className="complianceStatus-item">
                <div className="complianceStatus-color complianceStatus-color--green" />
                Compliant
            </div>
            <div className="complianceStatus-item">
                <div className="complianceStatus-color complianceStatus-color--red" />
              Not Compliant
            </div>
            <div className="complianceStatus-item">
                <div className="complianceStatus-color complianceStatus-color--orange" />
                In Progress
            </div>
            <div className="complianceStatus-item">
                <div className="complianceStatus-color complianceStatus-color--lightgreen" />
                Partially Compliant
            </div>
            <div className="complianceStatus-item">
                <div className="complianceStatus-color complianceStatus-color--verylightgreen" />
                Optional
            </div>
            <div className="complianceStatus-item">
                <div className="complianceStatus-color complianceStatus-color--yellow" />
                Grace Period
            </div>
            <div className="complianceStatus-item">
                <div className="complianceStatus-color complianceStatus-color--gray" />
                Not Applicable
            </div>
        </div>
    </div>
);

export default ComplianceStatus;