import { useLayoutEffect, useState } from 'react';

export const useDeviceType = () => {
  const [windowWidth, setWindowWidth] = useState(0);

  useLayoutEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateWindowWidth);
    updateWindowWidth();

    return () => window.removeEventListener('resize', updateWindowWidth);
  }, []);

  return windowWidth <= 600 ? 'mobile' : 'desktop';
}