import React, { Suspense, useEffect, useContext } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import { Context } from "./store/store";
import Breadcrumbs from "./v2mf/components/breadcrumbs/breadcrumbs";
import ChatBubble from "./v2mf/components/chat-bubble/chat-bubble";
import Notification from "./v2mf/components/notification/notification";
import Layout from "./hoc/Layout/Layout";
import Loader from "./v3mf/components/loader/loader";
import { runChatBotScript } from "./shared/services/avaamo";

const Auth = React.lazy(() => import("./v2mf/pages/auth/auth"));

const ApplicationsPage = React.lazy(() =>
  // import("./v2mf/pages/products/products")
  import("./v3mf/pages/applications/applications")
);

const ApmProductDetailsPage = React.lazy(() =>
  import("./v3mf/pages/apmProductDetails/apmProductDetails")
);

const Application = React.lazy(() =>
  import("./v2mf/pages/application/application")
);

const Flow = React.lazy(() => import("./v2mf/pages/flows/flows"));

const Chatbot = React.lazy(() => import("./v2mf/pages/chatbot/chatbot"));

const Audit = React.lazy(() => import("./v2mf/pages/audit/audit"));

const SonarScan = React.lazy(() => import("./v2mf/pages/audit/sonar"));

const Reports = React.lazy(() => import("./v2mf/pages/reports/reports"));

const ActivityReports = React.lazy(() =>
  import("./v2mf/pages/reports/activity/activity")
);

const SonarReports = React.lazy(() =>
  import("./v2mf/pages/reports/sonarqubeChargebacks/sonarqubeChargebacks")
);

const YearlyReports = React.lazy(() =>
  import("./v2mf/pages/reports/yearltProjection/yearlyProjection")
);

const PermissionManager = React.lazy(() =>
  import("./v2mf/pages/PermissionManager/PermissionManager")
);

const initializeReactGA = () => {
  ReactGA.initialize("G-FETJ5E40L1");
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

const App = () => {
  const [state] = useContext(Context);
  const location = useLocation();

  useEffect(() => {
    initializeReactGA();

    if (!state.user && !localStorage.getItem("redirecturl")) {
      localStorage.setItem("redirecturl", location.pathname);
    }
  }, [state.user, location.pathname]);

  let routes = (
    <Routes>
      <Route path="/v2/auth" element={<Auth />} />
      <Route path="*" element={<Navigate to="/v2/auth" />} />
    </Routes>
  );

  if (state.user) {
    routes = (
      <Routes>
        <Route path="/v2/auth" element={<Auth />} />
        <Route path="/v2/products/:id/audit" element={<Audit />} />
        <Route path="/v2/products/:id/sonarscan" element={<SonarScan />} />
        <Route path="/v2/products/:id/:refid/audit" element={<Audit />} />
        <Route path="/v2/products/:id/:refid" element={<Flow />} />
        <Route path="/v2/products/:id" element={<Application />} />
        <Route
          path="/v2/applications/:activeTab"
          element={<ApplicationsPage />}
        />
        <Route
          path="/v2/applications/apm/compliancerules"
          element={<ApplicationsPage />}
        />
        <Route
          path="/v2/applications/apm/:apmno/:activeTab"
          element={<ApmProductDetailsPage />}
        />
        <Route
          path="/v2/applications/apm/:apmno/overview/fmc"
          element={<ApmProductDetailsPage />}
        />
        <Route path="/v2/reports/activity" element={<ActivityReports />} />
        <Route path="/v2/reports/sonar" element={<SonarReports />} />
        <Route path="/v2/reports/yearly" element={<YearlyReports />} />
        <Route path="/v2/reports" element={<Reports />} />
        {/* <Route path="/v2/chatbot" element={<Chatbot />} /> */}
        <Route path="/v2/permissions" element={<PermissionManager />} />
        <Route path="/v2/permissions/:gid" element={<PermissionManager />} />
        <Route path="*" element={<Navigate to="/v2/applications/apm" />} />
      </Routes>
    );
  }

  return (
    <Layout>
      {state.user ? (
        <>
          {/* <ChatBubble></ChatBubble> */}
          <Breadcrumbs></Breadcrumbs>
          <Notification></Notification>
          {runChatBotScript()}
        </>
      ) : null}
      <Suspense fallback={<Loader />}>{routes}</Suspense>
    </Layout>
  );
};

export default App;
