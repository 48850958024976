import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import "./sideNavigation.scss";

import * as config from "../../../shared/config/config";
import msalService from "../../../shared/services/msal-service";
import { Context } from "../../../store/store";
import { useDeviceType } from "../../../shared/customHooks/useDeviceType";

import Icons from "../icons/index";
import Legend from "./legend/legend";

const SideNavigation = () => {
    const [state, dispatch] = useContext(Context);
    const deviceType = useDeviceType();
    const [displayLegend, setDisplayLegend] = useState(false);

    const logoutHandler = (event) => {
      event.preventDefault();
      dispatch({ type: config.ACTIONS.SET_USER, payload: null });
      msalService.logoutUser();
      localStorage.setItem("userLoggedOut", true);
    };

    const toggleLegend = () => {
        setDisplayLegend(prevDisplayLegend => !prevDisplayLegend);
    }
  
    return (
      <nav className="sideNavigation">
        <div className="logo">
          <Icons name="logo" width="40" />
        </div>
        {
            state.user 
                ? (
                    <div className="sideNavigation-navItems" data-testid="navItems">
                        <ul>
                            <li>
                                <NavLink to="/v2/products">
                                    <Icons name="home" />
                                </NavLink>
                            </li>
                            {/* {
                                deviceType === "desktop" &&
                                <li>
                                    <NavLink to="/v2/reports">
                                        <Icons name="reports" />
                                    </NavLink>
                                </li>
                            } */}
                            <li>
                                <a
                                    href="https://confluence.cbre.com/display/DevOps/DevOps+Portal"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Icons name="wiki" />
                                </a>
                            </li>
                            {
                                deviceType === "mobile" &&
                                <li>
                                    <NavLink to="/v2/chatbot">
                                        <Icons name="chat" width="20" />
                                    </NavLink>
                                </li>
                            }
                            <li>
                                <div onClick={logoutHandler}>
                                    <Icons name="signout" />
                                </div>
                            </li>
                            {
                                deviceType === "desktop" &&
                                <li className="sideNavigation-legendContainerItem">
                                    <div onClick={toggleLegend} data-testid="legendContainer">
                                        <Icons name="legend" />
                                    </div>
                                </li>
                            }
                        </ul>
                        { deviceType === "desktop" && displayLegend && <Legend setDisplayLegend={setDisplayLegend} /> }
                    </div>
                ) 
                : <></>
        }
      </nav>
    );
}

export default SideNavigation;