import React from "react";

const SVG = ({
  style = {},
  fill = "#435254",
  width = "13",
  height="9",
  className = "",
  viewBox="0 0 13 9",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M2.25 5.197V3.80229H10.75V5.197H2.25ZM0.125 0.25H12.875V1.678H0.125V0.25ZM5.072 8.75V7.322H7.92729V8.75H5.072V8.75Z"
    ></path>
  </svg>
);

export default SVG;