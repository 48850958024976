import React from "react";

const SVG = ({
  style = {},
  fill = "#000000",
  width = "24",
  className = "",
  viewBox="0 0 24 24" 
}) => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width={width} 
        height={width}
        viewBox={viewBox} 
        style={style}
        className={`svg-icon ${className || ""}`}
    >
        <title>x</title>
        <path 
            d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"
            fill={fill}
        />
    </svg>
);

export default SVG;