import { UserAgentApplication } from "msal";

const applicationConfig = {
  clientID: process.env.REACT_APP_CLIENT_ID,
};

const dynamicRedirectUri = () => {
  /* 
    We are dynamically figuring out our "protocol://host:port" so we can set our redirectUri to the /auth page instead of the default "whatever page we are on".
    The dynamic "whatever page we are on" behavior of msal worked fine for the /auth page to do logins, but now that we are doing graph calls from other pages
    and the call to get the token is integrated with msal and fails if the page we are on isn't /auth, we have to set the redirectUri explicitly to /auth. We
    can't use a relative path for this option, so we are using this function to build up the desired absolute URI in a way that doesn't require changes depending
    on which environment is running.
  */

  const [, root] = window.location.href.match(/^(.*?:\/\/.*?)\//);
  return `${root}/v2/auth`;
};

const msalConfig = {
  auth: {
    clientId: applicationConfig.clientID,
    authority:
      "https://login.microsoftonline.com/" +
      process.env.REACT_APP_AUTHORITY +
      "/",
    redirectUri: dynamicRedirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

let userAgentApplication = new UserAgentApplication(msalConfig);

export default userAgentApplication;
