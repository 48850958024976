import React from "react";

const SVG = ({
  style = {},
  fill = "#435254",
  width = "23",
  className = "",
  viewBox="0 0 23 23" 
}) => (
    <svg 
        style={style}
        width={width} 
        height={width}
        viewBox={viewBox} 
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
    >
        <path 
            fill={fill}
            d="M10 2.99991H3C2.172 2.99991 1.42 3.33691 0.879 3.87891C0.338 4.42091 0 5.17191 0 5.99991V19.9999C0 20.8279 0.337 21.5799 0.879 22.1209C1.421 22.6619 2.172 22.9999 3 22.9999H17C17.828 22.9999 18.58 22.6629 19.121 22.1209C19.662 21.5789 20 20.8279 20 19.9999V12.9999C20 12.4479 19.552 11.9999 19 11.9999C18.448 11.9999 18 12.4479 18 12.9999V19.9999C18 20.2759 17.889 20.5249 17.707 20.7069C17.525 20.8889 17.276 20.9999 17 20.9999H3C2.724 20.9999 2.475 20.8889 2.293 20.7069C2.111 20.5249 2 20.2759 2 19.9999V5.99991C2 5.72391 2.111 5.47491 2.293 5.29291C2.475 5.11091 2.724 4.99991 3 4.99991H10C10.552 4.99991 11 4.55191 11 3.99991C11 3.44791 10.552 2.99991 10 2.99991ZM16.793 1.79291L7.293 11.2929C7.171 11.4139 7.076 11.5729 7.03 11.7579L6.03 15.7579C5.991 15.9079 5.988 16.0759 6.03 16.2429C6.164 16.7789 6.707 17.1049 7.243 16.9709L11.243 15.9709C11.41 15.9299 11.573 15.8419 11.708 15.7079L21.208 6.20791C21.817 5.59891 22.122 4.79791 22.122 4.00091C22.122 3.20391 21.817 2.40291 21.208 1.79391C20.599 1.18491 19.797 0.878906 19 0.878906C18.203 0.878906 17.402 1.18391 16.793 1.79291ZM18.207 3.20691C18.426 2.98791 18.711 2.87891 19 2.87891C19.289 2.87891 19.574 2.98791 19.793 3.20691C20.012 3.42591 20.121 3.71091 20.121 3.99991C20.121 4.28891 20.012 4.57391 19.793 4.79291L10.489 14.0969L8.375 14.6259L8.904 12.5119L18.207 3.20691Z" 
        />
    </svg>
);

export default SVG;
