export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const loadFromLocalStorage = (key = "") => {
  try {
    const localStorageValue = localStorage.getItem(key);
    if (localStorageValue === null || localStorageValue === "") {
      return undefined;
    }
    return JSON.parse(localStorageValue);
  } catch (err) {
    return undefined;
  }
};
