import React from "react";
// import {Close} from "@material-ui/icons"
import "./legend.scss";

import ApmRecommendation from "./apmRecommendation/apmRecommendation";
import ComplianceStatus from "./complianceStatus/complianceStatus";
import SharedCodeCategories from "./sharedCodeCategories/sharedCodeCategories";

const Legend = ({setDisplayLegend}) => (
    <div className="legend" data-testid="legend">
        <p className="legend-title">
            Color Codes
            </p>
        <ApmRecommendation />
        <ComplianceStatus setDisplayLegend={setDisplayLegend} />
        <SharedCodeCategories />
    </div>
);

export default Legend;