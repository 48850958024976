import React from "react";

const SVG = ({
  style = {},
  fill = "#FFFFFF",
  width = "24",
  className = "",
  viewBox="0 0 24 24" 
}) => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width={width} 
        height={width}
        viewBox={viewBox} 
        style={style}
        fill={fill}
        className={`svg-icon ${className || ""}`}
    >
        <path 
            d="M3 2c-0.552 0-1 0.448-1 1v7c0 0.552 0.448 1 1 1h7c0.552 0 1-0.448 1-1v-7c0-0.552-0.448-1-1-1zM4 4h5v5h-5zM14 2c-0.552 0-1 0.448-1 1v7c0 0.552 0.448 1 1 1h7c0.552 0 1-0.448 1-1v-7c0-0.552-0.448-1-1-1zM15 4h5v5h-5zM14 13c-0.552 0-1 0.448-1 1v7c0 0.552 0.448 1 1 1h7c0.552 0 1-0.448 1-1v-7c0-0.552-0.448-1-1-1zM15 15h5v5h-5zM3 13c-0.552 0-1 0.448-1 1v7c0 0.552 0.448 1 1 1h7c0.552 0 1-0.448 1-1v-7c0-0.552-0.448-1-1-1zM4 15h5v5h-5z"
        />
    </svg>
);

export default SVG;