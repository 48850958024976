import React from "react";

const SVG = ({
  style = {},
  fill = "#777",
  width = "18",
  className = "",
  viewBox = "0 0 20 20"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M3.135 6.89c0.933-0.725 1.707-0.225 2.74 0.971 0.116 0.135 0.272-0.023 0.361-0.1 0.088-0.078 1.451-1.305 1.518-1.361 0.066-0.059 0.146-0.169 0.041-0.292-0.107-0.123-0.494-0.625-0.743-0.951-1.808-2.365 4.946-3.969 3.909-3.994-0.528-0.014-2.646-0.039-2.963-0.004-1.283 0.135-2.894 1.334-3.705 1.893-1.061 0.726-1.457 1.152-1.522 1.211-0.3 0.262-0.048 0.867-0.592 1.344-0.575 0.503-0.934 0.122-1.267 0.414-0.165 0.146-0.627 0.492-0.759 0.607-0.133 0.117-0.157 0.314-0.021 0.471 0 0 1.264 1.396 1.37 1.52 0.105 0.122 0.391 0.228 0.567 0.071 0.177-0.156 0.632-0.553 0.708-0.623 0.078-0.066-0.050-0.861 0.358-1.177zM8.843 7.407c-0.12-0.139-0.269-0.143-0.397-0.029l-1.434 1.252c-0.113 0.1-0.129 0.283-0.027 0.4l8.294 9.439c0.194 0.223 0.53 0.246 0.751 0.053l0.97-0.813c0.222-0.195 0.245-0.533 0.052-0.758l-8.209-9.544zM19.902 3.39c-0.074-0.494-0.33-0.391-0.463-0.182-0.133 0.211-0.721 1.102-0.963 1.506-0.24 0.4-0.832 1.191-1.934 0.41-1.148-0.811-0.749-1.377-0.549-1.758 0.201-0.383 0.818-1.457 0.907-1.59 0.089-0.135-0.015-0.527-0.371-0.363s-2.523 1.025-2.823 2.26c-0.307 1.256 0.257 2.379-0.85 3.494l-1.343 1.4 1.349 1.566 1.654-1.57c0.394-0.396 1.236-0.781 1.998-0.607 1.633 0.369 2.524-0.244 3.061-1.258 0.482-0.906 0.402-2.814 0.327-3.308zM2.739 17.053c-0.208 0.209-0.208 0.549 0 0.758l0.951 0.93c0.208 0.209 0.538 0.121 0.746-0.088l4.907-4.824-1.503-1.714-5.101 4.938z"
    ></path>
  </svg>
);

export default SVG;
