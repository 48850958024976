// Auth related actions
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

// Audit related actions
export const SUBMIT_AUDIT = "SUBMIT_AUDIT";
export const SUBMIT_AUDIT_FAIL = "SUBMIT_AUDIT_FAIL";
export const SUBMIT_AUDIT_SUCCESS = "SUBMIT_AUDIT_SUCCESS";

// Product related actions
export const SAVE_SEARCH_TEXT = "SAVE_SEARCH_TEXT";
export const SAVE_HEATMAP_DATA = "SAVE_HEATMAP_DATA";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_START = "FETCH_PRODUCTS_START";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAIL = "FETCH_PRODUCTS_FAIL";

// Product KPI related actions
export const FETCH_PRODUCTS_KPI = "FETCH_PRODUCTS_KPI";
export const FETCH_PRODUCTS_KPI_START = "FETCH_PRODUCTS_KPI_START";
export const FETCH_PRODUCTS_KPI_SUCCESS = "FETCH_PRODUCTS_KPI_SUCCESS";
export const FETCH_PRODUCTS_KPI_FAIL = "FETCH_PRODUCTS_KPI_FAIL";

// Notification related actions
export const RAISE_NOTIFICATON = "RAISE_NOTIFICATON";
export const RAISE_NOTIFICATON_START = "RAISE_NOTIFICATON_START";
export const RAISE_NOTIFICATON_END = "RAISE_NOTIFICATON_END";

// Permission related actions
export const FETCH_ENUMS_START = "FETCH_ENUMS_START";
export const FETCH_ENUMS_FAIL = "FETCH_ENUMS_FAIL";
export const FETCH_ENUMS_SUCCESS = "FETCH_ENUMS_SUCCESS";

export const FETCH_USER_PERMISSIONS_START = "FETCH_USER_PERMISSIONS_START";
export const FETCH_USER_PERMISSIONS_SUCCESS = "FETCH_USER_PERMISSIONS_SUCCESS";
export const FETCH_USER_PERMISSIONS_FAIL = "FETCH_USER_PERMISSIONS_FAIL";

export const FETCH_MANAGE_GROUP_PERMISSIONS_START =
  "FETCH_MANAGE_GROUP_PERMISSIONS_START";
export const FETCH_MANAGE_GROUP_PERMISSIONS_FAIL =
  "FETCH_MANAGE_GROUP_PERMISSIONS_FAIL";
export const FETCH_MANAGE_GROUP_PERMISSIONS_SUCCESS =
  "FETCH_MANAGE_GROUP_PERMISSIONS_SUCCESS";

export const FETCH_MANAGE_GROUP_USERS_START = "FETCH_MANAGE_GROUP_USERS_START";
export const FETCH_MANAGE_GROUP_USERS_SUCCESS =
  "FETCH_MANAGE_GROUP_USERS_SUCCESS";
export const FETCH_MANAGE_GROUP_USERS_FAIL = "FETCH_MANAGE_GROUP_USERS_FAIL";

export const SAVE_USER_ASSIGNMENT_START = "SAVE_USER_ASSIGNMENT_START";
export const SAVE_USER_ASSIGNMENT_SUCCESS = "SAVE_USER_ASSIGNMENT_SUCCESS";
export const SAVE_USER_ASSIGNMENT_FAIL = "SAVE_USER_ASSIGNMENT_FAIL";

export const DELETE_USER_ASSIGNMENT_START = "DELETE_USER_ASSIGNMENT_START";
export const DELETE_USER_ASSIGNMENT_SUCCESS = "DELETE_USER_ASSIGNMENT_SUCCESS";
export const DELETE_USER_ASSIGNMENT_FAIL = "DELETE_USER_ASSIGNMENT_FAIL";

export const FETCH_PRODUCT_KEYS_START = "FETCH_PRODUCT_KEYS_START";
export const FETCH_PRODUCT_KEYS_SUCCESS = "FETCH_PRODUCT_KEYS_SUCCESS";
export const FETCH_PRODUCT_KEYS_FAIL = "FETCH_PRODUCT_KEYS_FAIL";

export const CREATE_API_KEY_START = "CREATE_API_KEY_START";
export const CREATE_API_KEY_SUCCESS = "CREATE_API_KEY_SUCCESS";
export const CREATE_API_KEY_FAIL = "CREATE_API_KEY_FAIL";

export const DELETE_API_KEY_START = "DELETE_API_KEY_START";
export const DELETE_API_KEY_SUCCESS = "DELETE_API_KEY_SUCCESS";
export const DELETE_API_KEY_FAIL = "DELETE_API_KEY_FAIL";

export const CLEAR_MANAGE_GROUP_STATE = "CLEAR_MANAGE_GROUP_STATE";

//PROCESSES
export const FETCH_PROCESS = "FETCH_PROCESS";

//Compliance Table
export const FETCH_COMPLIANCE_RULES_START = "FETCH_COMPLIANCE_RULES_START";
export const FETCH_COMPLIANCE_RULES_SUCCESS = "FETCH_COMPLIANCE_RULES_SUCCESS";
export const FETCH_COMPLIANCE_RULES_FAIL = "FETCH_COMPLIANCE_RULES_FAIL";

//Sonar Qube Repoort
export const FETCH_SONAR_QUBE_START = "FETCH_SONAR_QUBE_START";
export const FETCH_SONAR_QUBE_SUCCESS = "FETCH_SONAR_QUBE_SUCCESS";
export const FETCH_SONAR_QUBE_FAIL = "FETCH_SONAR_QUBE_FAIL";

//APM Card Favorite Feature
export const FETCH_SAVED_FAVORITE_LIST = "FETCH_SAVED_FAVORITE_LIST";
export const TOGGLE_FAVORITE = "TOGGLE_FAVORITE";

//COMPLIANCE STRAGGLERS Changes
export const FETCH_COMPLIANCE_STRAGGLERS_START = "FETCH_COMPLIANCE_STRAGGLERS_START";
export const FETCH_COMPLIANCE_STRAGGLERS_SUCCESS = "FETCH_COMPLIANCE_STRAGGLERS_SUCCESS";
export const FETCH_COMPLIANCE_STRAGGLERS_FAIL = "FETCH_COMPLIANCE_STRAGGLERS_FAIL";