import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";


const initialState = {
    report:[],
    loadingReport: false
  };


const setReport = (state, action) => {
    return updateObject(state, {
      report: action.reportData,
      loadingReport: false
    });
  };

  const getReport = (state, action) => {
    return updateObject(state, {
      loadingReport: true
    });
  };

  const getReport_fail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loadingReport: false
    });
  };


  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FETCH_COMPLIANCE_STRAGGLERS_START:
        return getReport(state, action);
      case actionTypes.FETCH_COMPLIANCE_STRAGGLERS_SUCCESS:
        return setReport(state, action);
      case actionTypes.FETCH_COMPLIANCE_STRAGGLERS_FAIL:
        return getReport_fail(state, action);

      default:
        return state;
    }
  };

  export default reducer;