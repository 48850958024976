import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./breadcrumbs.scss";

const Breadcrumbs = (props) => {
  const [clumbs, setCrums] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const list = location.pathname.split("/").filter(function (e) {
      return e.replace(/(\r\n|\n|\r)/gm, "");
    });
    setCrums(list);
  }, [location.pathname]);

  const linkPath = (index) => {
    let path = "";
    for (var _i = 0; _i < index + 1; _i++) {
      path += "/" + clumbs[_i];
    }

    return path;
  };

  return (
    <React.Fragment>
      <ul className="breadcrumbs">
        <li>DevOps Portal</li>
        {clumbs.map((c, i) => {
          //linkPath(i);
          return (
            <li key={i}>
              <Link to={linkPath(i)}>{c}</Link>{" "}
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  );
};

export default Breadcrumbs;
