import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    component: null,
    operation: null,
    error: null
};

const raiseNoticationStart = ( state, action ) => {
    return updateObject( state, { 
        component: action.component,
        operation: action.operation,
        error: action.error
    });
};

const raiseNoticationEnd = ( state, action ) => {
    return updateObject( state, { 
        component: null,
        operation: null,
        error: null
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.RAISE_NOTIFICATON_START: return raiseNoticationStart(state, action);
        case actionTypes.RAISE_NOTIFICATON_END: return raiseNoticationEnd(state, action);
        default:
            return state;
    }
};

export default reducer;