const applicationConfig = {
  clientID:
    window && window.location.href.includes('dev.devopsportal')
      ? "3bcf4a76-32ae-44b4-ab14-ceb09fdaf450"
      : "83e7cb42-f6c5-4619-9014-8ffdf6897ccc",
};
let enabled;
export function runChatBotScript() {
  if (window && window.AvaamoChatBot && !enabled) {
    var chatBox = new window.AvaamoChatBot({
      url:
        "https://chatbot.cbre.com/web_channels/" + applicationConfig.clientID,
    });
    chatBox.load();
    enabled = true;
  }
}
