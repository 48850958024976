import "./loader.scss";

const Loader = () => (
    <div className="loaderContainer" data-testid="loaderContainer">
        <div className="loader">
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
)

export default Loader;