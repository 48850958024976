import axios from "axios";
import msalService from "./msal-service";

const getBaseURL = () => {
  const newurl =
    window.location.protocol + "//" + window.location.host + "/api/";

  return newurl;
};

function newInstanceJWT() {
  const instance = axios.create({
    baseURL: getBaseURL(),
  });
  //this interceptor is the magic hook to let us get a current token on each call
  instance.interceptors.request.use(addToken);

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        msalService.logoutUser();
      }
      // if (error.response.status === 404) {
      //   msalService.logoutUser();
      // }
      return Promise.reject(error);
    }
  );

  return instance;
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

async function addToken(config) {
  config.headers["Authorization"] =
    "Bearer " + getCookie(`msal.${process.env.REACT_APP_CLIENT_ID}.idtoken`);
  return config;
}

const instance = newInstanceJWT();
export default instance;
