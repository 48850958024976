import React, { useState } from "react";
import "./chattext.scss";

const Chattext = (props) => {
  const [text, setText] = useState("");
  const [formValid, setFormValid] = useState(false);

  const formChangeHandler = (event) => {
    if (event.target.id === "Chattext") {
      setText(event.target.value);

      setFormValid(event.target.value.trim().length > 0);
    }
  };

  const formSubmitHandler = (event) => {
    event.preventDefault();
    if (formValid) {
      props.recieveUserMessage(text);
      setText("");
    }
  };

  return (
    <form className="form-container" onSubmit={formSubmitHandler}>
      <div className="FormInput">
        <input
          type="text"
          required
          name="text"
          id="Chattext"
          value={text}
          onChange={formChangeHandler}
          placeholder="Question"
          autoComplete="off"
        />
      </div>
    </form>
  );
};

export default Chattext;
