import React from "react";

const SVG = ({
  style = {},
  fill = "#435254",
  width = "46",
  className = "",
  viewBox="0 0 46 46"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M23 0.919998C10.8054 0.919998 0.919983 10.8054 0.919983 23C0.919983 35.1946 10.8054 45.08 23 45.08C35.1923 45.08 45.08 35.1946 45.08 22.9977C45.08 10.8054 35.1923 0.919998 23 0.919998ZM22.6527 35.8777H22.5354C20.7368 35.8248 19.4672 34.4977 19.5201 32.7244C19.5707 30.981 20.8702 29.7137 22.609 29.7137L22.7148 29.7183C24.564 29.7712 25.8198 31.0845 25.7669 32.9084C25.714 34.6564 24.4375 35.8777 22.6527 35.8777ZM30.222 20.8564C29.7988 21.4544 28.8696 22.2042 27.6966 23.1173L26.404 24.0074C25.6956 24.5594 25.2678 25.0815 25.1091 25.5898C24.9803 25.99 24.9205 26.0981 24.909 26.9146V27.1216H19.9755L19.9893 26.703C20.0514 24.9918 20.0928 23.9798 20.8035 23.1449C21.919 21.8385 24.38 20.2515 24.4835 20.1848C24.8377 19.9203 25.1344 19.619 25.3552 19.2947C25.8727 18.5794 26.1004 18.0182 26.1004 17.4708C26.1004 16.7026 25.875 15.9919 25.4265 15.364C24.9941 14.7522 24.173 14.4486 22.9862 14.4486C21.8086 14.4486 21.0013 14.8212 20.5206 15.5894C20.0238 16.3737 19.7731 17.1994 19.7731 18.0435V18.2551H14.6878L14.697 18.0343C14.8281 14.9224 15.9413 12.6799 17.9975 11.3712C19.2924 10.5409 20.9047 10.12 22.7838 10.12C25.2402 10.12 27.3194 10.718 28.9524 11.8956C30.6107 13.0893 31.4502 14.8787 31.4502 17.2109C31.4479 18.515 31.0362 19.7409 30.222 20.8564Z"
    ></path>
  </svg>
);

export default SVG;