import React from "react";

const SVG = ({
  style = {},
  stroke = "#17E88F",
  strokeWidth = "2",
  width = "16",
  height= "28",
  className = "",
  viewBox="0 0 16 28"
}) => (
  <svg 
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path 
        d="M1.5 1L14.5 14L1.5 27" 
        stroke={stroke}
        strokeWidth={strokeWidth}
    />
  </svg>
);

export default SVG;