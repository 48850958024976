import React from "react";
import "./Layout.scss";

import SideNavigation from "../../v3mf/components/sideNavigation/sideNavigation";

const Layout = props => {
 
  return (
    <div className="container-main">
      <SideNavigation />
      <main>{props.children}</main>
    </div>
  );
};

export default Layout;
