import * as actionTypes from "../actions/actionTypes";

const initialState = {
  enums: null,
  userPermissions: null,
  groupPermissions: null,
  groupUsers: null,
  loadingEnums: false,
  loadingGroups: false,
  loadingGroupPermissions: false,
  loadingUsers: false,
  savingUserAssignment: false,
  deletingUserAssignment: false,
  apiKeys: null,
  loadingProductKeys: false,
  creatingProductKey: false,
  deletingProductKey: false,
  apiKeyResponse: null,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ENUMS_START:
      return { ...state, loadingEnums: true, error: null, enums: [] };
    case actionTypes.FETCH_ENUMS_FAIL:
      return { ...state, loadingEnums: false, error: action.error };
    case actionTypes.FETCH_ENUMS_SUCCESS:
      return { ...state, loadingEnums: false, enums: action.enums };
    case actionTypes.FETCH_USER_PERMISSIONS_START:
      return {
        ...state,
        loadingUserPermissions: true,
        error: null,
        userPermissions: []
      };
    case actionTypes.FETCH_USER_PERMISSIONS_FAIL:
      return { ...state, loadingUserPermissions: false, error: action.error };
    case actionTypes.FETCH_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loadingUserPermissions: false,
        userPermissions: action.userPermissions
      };
    case actionTypes.FETCH_MANAGE_GROUP_USERS_START:
      return { ...state, loadingGroupUsers: true, error: null, users: [] };
    case actionTypes.FETCH_MANAGE_GROUP_USERS_FAIL:
      return { ...state, loadingGroupUsers: false, error: action.error };
    case actionTypes.FETCH_MANAGE_GROUP_USERS_SUCCESS:
      return { ...state, loadingGroupUsers: false, groupUsers: action.users };
    case actionTypes.FETCH_MANAGE_GROUP_PERMISSIONS_START:
      return {
        ...state,
        loadingGroupPermissions: true,
        error: null,
        groupPermissions: []
      };
    case actionTypes.FETCH_MANAGE_GROUP_PERMISSIONS_FAIL:
      return { ...state, loadingGroupPermissions: false, error: action.error };
    case actionTypes.FETCH_MANAGE_GROUP_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loadingGroupPermissions: false,
        groupPermissions: action.groupPermissions
      };
    case actionTypes.SAVE_USER_ASSIGNMENT_START:
      return { ...state, savingUserAssignment: true, error: null };
    case actionTypes.SAVE_USER_ASSIGNMENT_FAIL:
      return { ...state, savingUserAssignment: false, error: action.error };
    case actionTypes.SAVE_USER_ASSIGNMENT_SUCCESS:
      return { ...state, savingUserAssignment: false };
    case actionTypes.DELETE_USER_ASSIGNMENT_START:
      return { ...state, deletingUserAssignment: true, error: null };
    case actionTypes.DELETE_USER_ASSIGNMENT_FAIL:
      return { ...state, deletingUserAssignment: false, error: action.error };
    case actionTypes.DELETE_USER_ASSIGNMENT_SUCCESS:
      return { ...state, deletingUserAssignment: false };
    case actionTypes.FETCH_PRODUCT_KEYS_START:
      return { ...state, loadingProductKeys: true, error: null, apiKeys: [] };
    case actionTypes.FETCH_PRODUCT_KEYS_FAIL:
      return { ...state, loadingProductKeys: false, error: action.error };
    case actionTypes.FETCH_PRODUCT_KEYS_SUCCESS:
      return { ...state, loadingProductKeys: false, apiKeys: action.apiKeys };
    case actionTypes.CREATE_API_KEY_START:
      return {
        ...state,
        creatingProductKey: true,
        error: null,
        apiKeyResponse: null
      };
    case actionTypes.CREATE_API_KEY_FAIL:
      return { ...state, creatingProductKey: false, error: action.error };
    case actionTypes.CREATE_API_KEY_SUCCESS:
      return {
        ...state,
        creatingProductKey: false,
        apiKeyResponse: action.response
      };
    case actionTypes.DELETE_API_KEY_START:
      return { ...state, deletingProductKey: true, error: null };
    case actionTypes.DELETE_API_KEY_FAIL:
      return { ...state, deletingProductKey: false, error: action.error };
    case actionTypes.DELETE_API_KEY_SUCCESS:
      return { ...state, deletingProductKey: false };
    case actionTypes.CLEAR_MANAGE_GROUP_STATE:
      return {
        ...state,
        groupPermissions: [],
        groupUsers: [],
        apiKeys: []
      };
    default:
      return state;
  }
};

export default reducer;
