export const APP_NAME = "DevOps Portal";

export const AUDIT_OPERATIONS = {
  UPDATE_PROCESS: "UPDATE_PROCESS",
  GET_FLOW: "GET_FLOW",
  UPDATE_FLOW: "UPDATE_FLOW",
  CREATE_FLOW: "CREATE_FLOW",
  GET_PROCESS_COUNTS: "GET_PROCESS_COUNTS",
  CHANGE_ENV: "CHANGE_ENV",
  METRIC_POST: "METRIC_POST",
  FETCH_ENUMS: "FETCH_ENUMS",
  FETCH_USER_PERMISSIONS: "FETCH_USER_PERMISSIONS",
  FETCH_MANAGE_GROUP_PERMISSIONS: "FETCH_MANAGE_GROUP_PERMISSIONS",
  FETCH_MANAGE_GROUP_USERS: "FETCH_MANAGE_GROUP_USERS",
  SAVE_USER_ASSIGNMENT: "SAVE_USER_ASSIGNMENT",
  DELETE_USER_ASSIGNMENT: "DELETE_USER_ASSIGNMENT",
  CHECK_PERMISSION: "CHECK_PERMISSION",
  PRODUCT_APPLICABLE: "PRODUCT_APPLICABLE",
  PRODUCT_NOT_APPLICABLE: "PRODUCT_NOT_APPLICABLE",
  PRODUCT_EXCEPTION: "PRODUCT_EXCEPTION",
  PROCESS_APPROVAL: "PROCESS_APPROVED",
  CREATE_PRODUCT_KEY: "CREATE_PRODUCT_KEY",
  DELETE_PRODUCT_KEY: "DELETE_PRODUCT_KEY",
};

export const COMPONENT = {
  PRODUCT: "PRODUCT",
  FLOW: "FLOW",
  MANAGE_USERS: "MANAGE_USERS",
  COMPLIANCE_RULES:"COMPLIANCE_RULES",
  SONAR_QUBE_REPORT: "SONAR_QUBE_REPORT",
  COMPLIANCE_STRAGGLERS: "COMPLIANCE_STRAGGLERS"
};

export const ACTIONS = {
  SET_USER: "SET_USER",
  SET_NOTIFICATION: "SET_NOTIFICATION",
  SET_APM_PRODUCTS: "SET_APM_PRODUCTS",
  SET_SELECTED_APM_PRODUCT_NAME: "SET_SELECTED_APM_PRODUCT_NAME",
  SET_SHARED_PRODUCTS: "SET_SHARED_PRODUCTS",
  SET_APM_PRODUCT_OVERVIEW: "SET_APM_PRODUCT_OVERVIEW",
  SET_APM_PRODUCT_PHASES: "SET_APM_PRODUCT_PHASES",
  SET_APM_PRODUCT_GATES: "SET_APM_PRODUCT_GATES",
  SET_SELECTED_GATE_INDEX_FROM_OVERVIEW: "SET_SELECTED_GATE_INDEX_FROM_OVERVIEW",
  SET_QUICK_FIND_DROPDOWN_OPTIONS: "SET_QUICK_FIND_DROPDOWN_OPTIONS",
  SET_SELECTED_QUICK_FIND_DROPDOWN_OPTION: "SET_SELECTED_QUICK_FIND_DROPDOWN_OPTION",
  SET_APM_PRODUCT_FLOWS: "SET_APM_PRODUCT_FLOWS",
  SET_PROCESSES: "SET_PROCESSES",
  SUBMIT_AUDIT: "SUBMIT_AUDIT",
  SET_SEARCH_TEXT: "SET_SEARCH_TEXT",
  SET_SELECTED_FILTERS: "SET_SELECTED_FILTERS",
  SET_SELECTED_SWITCH_FILTER: "SET_SELECTED_SWITCH_FILTER"
};

export const api_urls = {
  GET_APM_APPLICATIONS_CONF: { 
    url: "v3/application", 
    method: "get" 
  },

  GET_SHARED_COMPONENTS_CONF: {
    url: "v4/sharedcomponents", 
    method: "get" 
  },
  GET_APM_PRODUCT_OVERVIEW: {
    url: "v5/product/overview/",
    method: "get",
  },
  GET_ACTIVITY: {
    url: 'v4/audit/notes',  // v4/audit/notes/:type/:apmkey
    method: "get"
  },
  SAVE_TRACK_COMPLIANCE_STATUS: {
    url: 'v4/audit/notes',
    method: "post"
  },
  GET_APM_PRODUCT_PHASES: {
    url: "v4/product/phases/",
    method: "get",
  },
  GET_APM_PRODUCT_GATES: {
    url: "v4/product/gates/",
    method: "get",
  },
  GET_APM_PRODUCT_GATE_ACTIVE_PROCESS_DETAIL: {
    url: "v4/product/process",
    method: "get",
  },
  GET_AUDIT_CONF: { 
    url: "v3/audit_history", 
    method: "get" 
  },
  GET_METRICS_FAILURE: {
    url: "v4/flow/metricfailures", 
    method: "get" 
  },
  GET_SONARQUBE_ACTIVITY: {
    url: "/v3/sonarqube/compliance",
    method: "get"
  },
  GET_FLOWS: {
    url: 'v4/product/flows/',
    method: "get"
  },
  GET_FLOW: {
    url: 'v4/flow',
    method: "get"
  },
  SAVE_FMC: {
    url: "v4/product",
    method: "post"
  },
  SAVE_FLOW: {
    url: 'v4/flow',
    method: "post"
  },
  CREATE_FLOW: {
    url: 'v4/flow/create',
    method: "post"
  },
  SET_PRODUCT_EXCEPTION: {
    url: "v5/process/exception",
    method: "post",
  },
  UPDATE_FLOW_APPLICABILITY: {
    url: "v4/flow/process",   //  v4/flow/process/:RefId/:ProcessId
    method: "post",
  },
  UPDATE_GATE_APPLICABILITY: {
    url: "v5/process/applicable",
    method: "post",
  },
  GET_SONARQUBE_MAP: {
    url: "v5/sonarqube/unmapped",
    method: "get"
  },
  MAP_SONARQUBE_TO_FLOW: {
    url: "v5/sonarqube/map",
    method: "post"
  },
  GET_ACTIVITY_REPORT_CONF: {
    url: "v3/report/completion-trend/week/6",
    method: "get",
  },
  GET_SONAR_REPORT_CONF: {
    url: "v3/report/sonarqube-trend/6",
    method: "get",
  },
  GET_PROCESSES_CONF: {
    url: "v2/process",
    method: "get",
  },
  GET_FLOW_DETAIL_CONF: {
    url: "v2/application/",
    method: "get",
  },
  GET_METRICS_CONF: {
    url: "v5/flow/metrics/",
    method: "get",
  },
  GET_METRICS_COUNTS_CONF: {
    url: "v2/metricscount/",
    method: "get",
  },
};

export const INFO_REDIRECT_URLS = {
  APM_APPLICATIONS_LIST: {
    link: "https://confluence.cbre.com/display/DevOps/APM+Applications+List"
  },
  SHARED_COMPONENTS_LIST: {
    link: "https://confluence.cbre.com/display/DevOps/Shared+Components+List"
  },
  APM_APPLICATIONS_OVERVIEW : {
    link: "https://confluence.cbre.com/display/DevOps/Overview"
  },
  APM_APPLICATIONS_GATES: {
    link: "https://confluence.cbre.com/display/DevOps/Gates"
  },
  APM_APPLICATIONS_PHASE: {
    link: "https://confluence.cbre.com/display/DevOps/Phase"
  },
  APM_APPLICATIONS_FLOWS: {
    link: "https://confluence.cbre.com/pages/viewpage.action?pageId=131014819"
  }
}
