import React from "react";
import Logo from "./Logo";
import Home from "./Home";
import Wiki from "./Books";
import SignOut from "./Logout";
import Star from "./Star";
import StopCircle from "./Stop-Circle";
import Back from "./Back";
import AddtoList from "./AddtoList";
import Copy from "./Copy";
import AreaGraph from "./Area-Graph";
import Build from "./Build";
import Invest from "./Invest";
import UserCheck from "./UserCheck";
import UserX from "./UserX";
import Done from "./Done";
import Close from "./Close";
import Chat from "./Chat";
import Info from "./Info";
import Bell from "./Bell";
import Beta from "./Beta";
import Filter from "./Filter";
import Dots from "./Dots";
import Reports from "./Reports";
import TrendUp from "./Trend-Up";
import TrendDown from "./Trend-Down";
import TrendIdentical from "./Trend-Identical";
import Download from "./Download";
import Shield from "./Shield";
import ShieldOff from "./ShieldOff";
import AlertOcto from "./AlertOcto";
import ExternalLink from "./ExternalLink";
import Help from "./Help";

const icons = (props) => {
  switch (props.name) {
    case "logo":
      return <Logo {...props} />;
    case "home":
      return <Home {...props} />;
    case "wiki":
      return <Wiki {...props} />;
    case "signout":
      return <SignOut {...props} />;
    case "star":
      return <Star {...props} />;
    case "stop-circle":
      return <StopCircle {...props} />;
    case "back":
      return <Back {...props} />;
    case "addtolist":
      return <AddtoList {...props} />;
    case "copy":
      return <Copy {...props} />;
    case "areagraph":
      return <AreaGraph {...props} />;
    case "build":
      return <Build {...props} />;
    case "invest":
      return <Invest {...props} />;
    case "usercheck":
      return <UserCheck {...props} />;
    case "userx":
      return <UserX {...props} />;
    case "done":
      return <Done {...props} />;
    case "close":
      return <Close {...props} />;
    case "info":
      return <Info {...props} />;
    case "chat":
      return <Chat {...props} />;
    case "bell":
      return <Bell {...props} />;
    case "beta":
      return <Beta {...props} />;
    case "filter":
      return <Filter {...props} />;
    case "dots":
      return <Dots {...props} />;
    case "reports":
      return <Reports {...props} />;
    case "trendup":
      return <TrendUp {...props} />;
    case "trenddown":
      return <TrendDown {...props} />;
    case "trendidentical":
      return <TrendIdentical {...props} />;
    case "download":
      return <Download {...props} />;
    case "shield":
      return <Shield {...props} />;
    case "shieldoff":
      return <ShieldOff {...props} />;
    case "alertotco":
      return <AlertOcto {...props} />;
    case "link":
      return <ExternalLink {...props} />;
    case "help":
      return <Help {...props} />;

    default:
      return;
  }
};

export default icons;
