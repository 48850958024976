import React from "react";

const SVG = ({
  style = {},
  fill = "#435254",
  width = "18",
  height= "18",
  className = "",
  viewBox="0 0 18 18"
}) => (
    <svg 
        width={width}
        style={style}
        height={height}
        viewBox={viewBox} 
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
    >
        <path 
            d="M13.1667 9.83329V14.8333C13.1667 15.0633 13.0742 15.2708 12.9225 15.4225C12.7709 15.5741 12.5634 15.6666 12.3334 15.6666H3.16669C2.93669 15.6666 2.72919 15.5741 2.57752 15.4225C2.42585 15.2708 2.33335 15.0633 2.33335 14.8333V5.66663C2.33335 5.43663 2.42585 5.22913 2.57752 5.07746C2.72919 4.92579 2.93669 4.83329 3.16669 4.83329H8.16669C8.62669 4.83329 9.00002 4.45996 9.00002 3.99996C9.00002 3.53996 8.62669 3.16663 8.16669 3.16663H3.16669C2.47669 3.16663 1.85002 3.44746 1.39919 3.89913C0.948354 4.35079 0.666687 4.97663 0.666687 5.66663V14.8333C0.666687 15.5233 0.94752 16.15 1.39919 16.6008C1.85085 17.0516 2.47669 17.3333 3.16669 17.3333H12.3334C13.0234 17.3333 13.65 17.0525 14.1009 16.6008C14.5517 16.1491 14.8334 15.5233 14.8334 14.8333V9.83329C14.8334 9.37329 14.46 8.99996 14 8.99996C13.54 8.99996 13.1667 9.37329 13.1667 9.83329ZM7.92252 11.2558L15.6667 3.51163V6.49996C15.6667 6.95996 16.04 7.33329 16.5 7.33329C16.96 7.33329 17.3334 6.95996 17.3334 6.49996V1.49996C17.3334 1.38663 17.3109 1.27913 17.27 1.18079C17.2292 1.08246 17.1692 0.990793 17.09 0.911626C17.0892 0.910793 17.0892 0.910793 17.0884 0.909959C17.0117 0.833293 16.92 0.771626 16.8192 0.729959C16.7209 0.689126 16.6134 0.666626 16.5 0.666626H11.5C11.04 0.666626 10.6667 1.03996 10.6667 1.49996C10.6667 1.95996 11.04 2.33329 11.5 2.33329H14.4884L6.74419 10.0775C6.41835 10.4033 6.41835 10.9308 6.74419 11.2558C7.07002 11.5808 7.59752 11.5816 7.92252 11.2558Z" 
            fill={fill}
        />
    </svg>
);

export default SVG;