import React from "react";
import "./sectionheading.scss";

const sectionheading = props => {
  return (
    <div className="sectionheading">
      <h4>{props.children}</h4>
    </div>
  );
};

export default sectionheading;
