import React from "react";

const SVG = ({
  style = {},
  fill = "#FFFFFF",
  width = "24",
  className = "",
  viewBox="0 0 24 24" 
}) => (
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width={width} 
        height={width}
        viewBox={viewBox} 
        style={style}
        fill="transparent"
        stroke={fill} 
        strokeWidth="2" 
        strokeLinecap="" 
        strokeLinejoin="" 
        className={`svg-icon ${className || ""}`}
    >
        <polyline points="11 17 6 12 11 7"/>
        <polyline points="18 17 13 12 18 7"/>
    </svg>
);

export default SVG;