import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  products: [],
  productsKpi: null,
  loadingProducts: false,
  loadingProductsKpi: false,
  error: null,
  searchText: "",
  heatmap: []
};

const saveProducts = (state, action) => {
  return updateObject(state, {
    products: action.products,
    loadingProducts: false
  });
};

const saveProductsStart = (state, action) => {
  return updateObject(state, {
    loadingProducts: true
  });
};

const saveProductsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadingProducts: false
  });
};

const saveProductsKpi = (state, action) => {
  return updateObject(state, {
    productsKpi: action.productsKpi,
    loadingProductsKpi: false
  });
};

const saveProductsStartKpi = (state, action) => {
  return updateObject(state, {
    loadingProductsKpi: true
  });
};

const saveProductsFailKpi = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadingProductsKpi: false
  });
};

const saveSearchText = (state, action) => {
  return updateObject(state, {
    searchText: action.searchText
  });
};

const saveHeatmapData = (state, action) => {
  return updateObject(state, {
    heatmap: action.heatmap
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCTS_START:
      return saveProductsStart(state, action);
    case actionTypes.FETCH_PRODUCTS_SUCCESS:
      return saveProducts(state, action);
    case actionTypes.FETCH_PRODUCTS_FAIL:
      return saveProductsFail(state, action);
    case actionTypes.FETCH_PRODUCTS_KPI_START:
      return saveProductsStartKpi(state, action);
    case actionTypes.FETCH_PRODUCTS_KPI_SUCCESS:
      return saveProductsKpi(state, action);
    case actionTypes.FETCH_PRODUCTS_KPI_FAIL:
      return saveProductsFailKpi(state, action);
    case actionTypes.SAVE_SEARCH_TEXT:
      return saveSearchText(state, action);
    case actionTypes.SAVE_HEATMAP_DATA:
      return saveHeatmapData(state, action);
    default:
      return state;
  }
};

export default reducer;
