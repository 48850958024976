import React from "react";

const SVG = ({
  style = {},
  fill = "#435254",
  width = "26",
  height="19",
  className = "",
  viewBox="0 0 26 19"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path 
      d="M23.3318 0.498402L8.75007 15.0802L2.66832 8.9984C2.1144 8.44449 1.21765 8.44449 0.665151 8.9984C0.112651 9.55232 0.111234 10.4491 0.665151 11.0016L7.74848 18.0849C8.3024 18.6388 9.19915 18.6388 9.75165 18.0849L25.335 2.50157C25.8889 1.94765 25.8889 1.0509 25.335 0.498402C24.7811 -0.0540975 23.8843 -0.0555142 23.3318 0.498402V0.498402Z" 
      fill={fill}
    />
  </svg>
);

export default SVG;