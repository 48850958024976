import React from "react";

const SVG = ({
  style = {},
  fill = "#777",
  width = "20",
  className = "",
  viewBox = "0 0 20 20"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M20 2v16h-19.68c-0.318 0-0.416-0.209-0.216-0.465l4.469-5.748c0.199-0.256 0.553-0.283 0.789-0.062l1.419 1.334c0.235 0.221 0.572 0.178 0.747-0.096l3.047-4.74c0.175-0.273 0.509-0.312 0.741-0.090l2.171 2.096c0.232 0.225 0.559 0.18 0.724-0.1l5.133-7.785c0.166-0.282 0.406-0.344 0.656-0.344z"
    ></path>
  </svg>
);

export default SVG;
