import React from "react";
import "./sharedCodeCategories.scss";

const SharedCodeCategories = () => (
    <div className="sharedCodeCategories" data-testid="sharedCodeCategories">
        <p className="sharedCodeCategories-title">
            Shared Code Categories
        </p>
        <div className="sharedCodeCategories-items">
            <div className="sharedCodeCategories-item">
                <div className="sharedCodeCategories-color sharedCodeCategories-color--github" />
                Github
            </div>
            <div className="sharedCodeCategories-item">
                <div className="sharedCodeCategories-color sharedCodeCategories-color--azure" />
                Azure DevOps
            </div>
        </div>
    </div>
);

export default SharedCodeCategories;