import React, { useEffect, useContext } from "react";
import "./notification.scss";
import * as config from "../../../shared/config/config";

import SectionHeading from "../sectionheading/sectionheading";
import Icons from "../icons";

import { Context } from "../../../store/store";

const Notification = (props) => {
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    if (state.message) {
      setTimeout(() => {
        dispatch({
          type: config.ACTIONS.SET_NOTIFICATION,
          payload: null,
        });
      }, 5000);
    }
  }, [state.message]);

  return (
    <div
      className="Notification"
      style={{
        height: state.message ? 100 : 0,
      }}
    >
      <div className="Notification-box">
        <SectionHeading>
          <Icons name="bell"></Icons> Notification
        </SectionHeading>
        <p>{state.message}</p>
      </div>
    </div>
  );
};

export default Notification;
