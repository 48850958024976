import React from "react";

const SVG = ({
  style = {},
  fill = "#838383",
  width = "25",
  className = "",
  viewBox = "0 0 24 24"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M6 14.016h2.016q0 1.641 1.172 2.813t2.813 1.172 2.813-1.172 1.172-2.813h2.016q0 2.484-1.758 4.242t-4.242 1.758-4.242-1.758-1.758-4.242zM11.016 5.016h1.969v5.578h3.516l-4.5 4.5-4.5-4.5h3.516v-5.578z"
    ></path>
  </svg>
);

export default SVG;
