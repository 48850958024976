import "react-app-polyfill/ie11";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import authReducer from "./store/reducers/auth";
import productsReducer from "./store/reducers/products";
import auditReducer from "./store/reducers/audit";
import notificationReducer from "./store/reducers/notification";
import permissionReducer from "./store/reducers/permissions";
import processReducer from "./store/reducers/process";
import complianceRulesReducer from './store/reducers/complianceRules';
import sonarQubeReportReducer from './store/reducers/sonarQubeReport';
import favoritesReducer from "./store/reducers/favorite";
import complianceStragglersReducer from "./store/reducers/complianceStragglers"

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Store from "./store/store";

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const rootReducer = combineReducers({
  auth: authReducer,
  servicenow: productsReducer,
  complianceRules:complianceRulesReducer,
  audit: auditReducer,
  notification: notificationReducer,
  permissions: permissionReducer,
  process: processReducer,
  sonarQubeReport: sonarQubeReportReducer,
  favorites: favoritesReducer,
  complianceStragglersReport: complianceStragglersReducer
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <Store>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Store>
  </Provider>
);

const container = document.getElementById('root')
const root = createRoot(container);
root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
