import React from "react";
import "./apmRecommendation.scss";

const ApmRecommendation = () => (
    <div className="apmRecommendation" data-testid="apmRecommendation">
        <p className="apmRecommendation-title">
            APM Recommendation
        </p>
        <div className="apmRecommendation-items">
            <div className="apmRecommendation-item">
                <div 
                    className="apmRecommendation-color apmRecommendation-color--invest" 
                    id="apmRecommendation-color--invest"
                />
                Invest
            </div>
            <div className="apmRecommendation-item">
                <div 
                    className="apmRecommendation-color apmRecommendation-color--sustain"
                    id="apmRecommendation-color--sustain"
                />
                Sustain
            </div>
            <div className="apmRecommendation-item">
                <div 
                    className="apmRecommendation-color apmRecommendation-color--decommission"
                    id="apmRecommendation-color--decommission"
                />
                Decomm
            </div>
        </div>
    </div>
);

export default ApmRecommendation;