import React from "react";

const SVG = ({
  style = {},
  fill = "#fff",
  width = "100%",
  className = "",
  viewBox = "0 0 92 40"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M0,30 L10,30 C15.5228475,30 20,25.5228475 20,20 C20,14.4771525 15.5228475,10 10,10 L0,10 L0,0 L10,0 C21.045695,-2.02906125e-15 30,8.954305 30,20 C30,31.045695 21.045695,40 10,40 L0,40 L0,30 Z"
      id="Combined-Shape"
    ></path>
    <path
      fill={fill}
      d="M60,25 L60,14.8946115 L77.66439,14.8946115 C79.0159997,14.8946115 80.1116958,13.7989154 80.1116958,12.4473057 C80.1116958,11.0956961 79.0159997,10 77.66439,10 L60,10 L60,0 L78.6116958,-1.77635684e-15 C85.5152552,-3.04452012e-15 91.1116958,5.59644063 91.1116958,12.5 C91.1116958,19.4035594 85.5152552,25 78.6116958,25 L70,25 L70,40 L60,40 L60,25 Z M40,40 C28.954305,40 20,31.045695 20,20 C20,8.954305 28.954305,0 40,-7.10542736e-15 C51.045695,-7.10542736e-15 60,8.954305 60,20 C60,31.045695 51.045695,40 40,40 Z M40,30 C45.5228475,30 50,25.5228475 50,20 C50,14.4771525 45.5228475,10 40,10 C34.4771525,10 30,14.4771525 30,20 C30,25.5228475 34.4771525,30 40,30 Z"
      id="Combined-Shape"
    ></path>
  </svg>
);

export default SVG;
