import React, { createContext, useReducer } from "react";
import Reducer from "./storeReducer";

const initialState = {
  // Recently used ones.
  apmProducts: [],
  sharedProducts: [],
  selectedApmProductName: "",
  apmProductOverview: {},
  apmProductPhases: {},
  apmProductGates: {},
  selectedGateIndexFromOverview: "",
  quickFindDropdownOptions: [],
  selectedQuickFindDropdownOption: {},
  apmProductFlows: {},
  selectedSwitchFilter: {},
  message: null,
  table_data:[],
  user: null,
  products: [],   //Keeping for v2mf incase we need it.
  process: [],
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
