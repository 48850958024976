import React, { useState, useEffect } from "react";
import "./chat.scss";
import ChatText from "./../forms/chattext/chattext";
import botaxios from "../../../shared/services/axios-chatbot";
import SectionHeading from "../sectionheading/sectionheading";
import Icons from "../icons";

const Chat = () => {
  const [loading, setLoading] = useState(false);
  const [sessionid, setSessionID] = useState("");
  const [showOptions, setShowOption] = useState(false);
  const [data, setData] = useState(null);
  const [chatMessages, setChatMessages] = useState([
    {
      who: "bot",
      message: "Hi, I am DOP bot!",
    },
  ]);

  const recieveUserMessage = (message) => {
    const m = [...chatMessages];
    m.push({
      who: "user",
      message: message,
    });
    setLoading(true);
    setChatMessages(m);

    callBot(m, message);
  };

  const recieveBotMessage = (list, message) => {
    setData(message);
    if (message.options && message.options.length === 0) {
      const m = [...list];
      m.push({
        who: "bot",
        message: message.fulfillmenttext,
        link: message.url,
      });
      setChatMessages(m);
      setShowOption(false);
    } else {
      setShowOption(true);
    }
    setLoading(false);
  };

  const callBot = (list, message) => {
    const data = {
      inputtext: message,
      sessionid: sessionid,
    };

    botaxios
      .post("/intent", data)
      .then((response) => {
        if (response.data.status !== "FAILURE") {
          recieveBotMessage(list, response.data.data);
        }
      })
      .catch((error) => {
        let errMessage;
        if (error.response) {
          errMessage = error.response.data;
        } else if (error.request) {
          errMessage = "No response was received from server";
        } else {
          errMessage = error.message;
        }
      });
  };

  const renderLoading = () => {
    return (
      <svg
        id="dots"
        width="50px"
        height="30px"
        viewBox="0 0 132 58"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>dots</title>
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="dots" fill="#A3A3A3">
            <circle id="dot1" cx="25" cy="30" r="13"></circle>
            <circle id="dot2" cx="65" cy="30" r="13"></circle>
            <circle id="dot3" cx="105" cy="30" r="13"></circle>
          </g>
        </g>
      </svg>
    );
  };

  useEffect(() => {
    const list = document.getElementById("list");
    list.scrollTop = list.offsetHeight;
  }, [chatMessages]);

  useEffect(() => {
    let uniqueId =
      Math.random().toString(36).substring(2) + Date.now().toString(36);
    const m = [...chatMessages];
    m.push({
      who: "bot",
      message: "New session created : " + uniqueId,
    });
    setSessionID(uniqueId);
    setChatMessages(m);
    scrollUp();
  }, []);

  const scrollUp = () => {
    const list = document.getElementById("list");
    list.scrollTop = list.offsetHeight;
  };

  const showSelectedOption = (option) => {
    const m = [...chatMessages];
    m.push({
      who: "bot",
      message: option.FulfillmentText,
      link: option.url,
    });
    setChatMessages(m);
    setShowOption(false);
    setData(null);
  };

  return (
    <div className="Chat">
      <SectionHeading>
        DevOps Chat bot <Icons name="beta" fill="#435254"></Icons>
      </SectionHeading>

      <ul id="list">
        {chatMessages.map((m, i) => {
          return (
            <li className={m.who} key={i}>
              <div className="Chat-MessageBox">
                <p>
                  <span>{m.message}</span>
                </p>
                <p>
                  {m.link ? (
                    <span>
                      <a
                        href={m.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click to open
                      </a>
                    </span>
                  ) : null}
                </p>
              </div>
            </li>
          );
        })}

        {loading ? (
          <li className="load">
            <div className="Chat-MessageBox">
              <p>
                <span>{renderLoading()}</span>
              </p>
            </div>
          </li>
        ) : null}
      </ul>
      {showOptions ? (
        <div className="Chat-Options">
          <ul>
            <li className="title">Select appropriate option,</li>
            {data.options &&
              data.options.map((m, i) => {
                return (
                  <li key={i} onClick={() => showSelectedOption(m)}>
                    {m.Options}
                  </li>
                );
              })}
          </ul>
        </div>
      ) : null}

      <ChatText recieveUserMessage={recieveUserMessage}></ChatText>
    </div>
  );
};

export default Chat;
